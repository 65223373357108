import { useMemo, useRef, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import set from 'lodash/set';
import { sum, cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import { useSaveTableMutation } from 'shared/api';
import { Button } from 'shared/ui/Button';
import { IconArchive, IconFile, IconImage, IconRotateCcw } from 'shared/ui/Icon';
import './index.css';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

type TTableProps = {
    dataTable: unknown;
    onResetDataTable: () => void;
    resultProcess: any;
};

const EditableTd = ({ value, name = [], sumUpdate }: { value: string; name?: string[]; sumUpdate: any }) => {
    const [tdValue, setTdValue] = useState<string>(value);

    return (
        <td align="center">
            <span>{tdValue}</span>
            <input
                className={tdValue == value ? '' : 'changed'}
                type="number"
                name={name.join('/')}
                value={tdValue}
                onChange={(event) => {
                    setTdValue(event.target.value);
                    sumUpdate(event.target.value);
                }}
            />
        </td>
    );
};

const SumTd = ({ value, name }: { value: number; name: any }) => {
    return (
        <td align="center">
            <span style={{ display: 'block' }}>{value}</span>
            <input hidden={true} type="number" name={name.join('/')} defaultValue={value} />
        </td>
    );
};

const newForm: any = {};

const Table = ({ dataTable, onResetDataTable, resultProcess }: TTableProps) => {
    const token = useSelector((state: RootState) => state.auth.token);
    const tableRef = useRef(null);
    const [isDebounced, setIsDebounced] = useState(false);
    const [saveTable] = useSaveTableMutation();

    const [distalInterphalangealJoints, setDistalInterphalangealJoints] = useState([
        [
            get(dataTable, ['Без имени', '1-й МФ', 'Справа', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Эрозии']),
        ],
        [
            get(dataTable, ['Без имени', '1-й МФ', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Сужение щелей']),
        ],
        [
            get(dataTable, ['Без имени', '1-й МФ', 'Слева', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Эрозии']),
        ],
        [
            get(dataTable, ['Без имени', '1-й МФ', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Сужение щелей']),
        ],
    ]);

    const [proximalInterphalangealJoints, setProximalInterphalangealJoints] = useState([
        [
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Эрозии']),
        ],
        [
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Сужение щелей']),
        ],
        [
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Эрозии']),
        ],
        [
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Сужение щелей']),
        ],
    ]);

    const [metacarpophalangealJoints, setMetacarpophalangealJoints] = useState([
        [
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Справа', 'Эрозии']),
        ],
        [
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Сужение щелей']),
            0,
        ],
        [
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Эрозии']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Слева', 'Эрозии']),
        ],
        [
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Сужение щелей']),
            0,
        ],
    ]);

    const [wristJoints, setWristJoints] = useState([
        [
            0,
            0,
            0,
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Эрозии']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Справа', 'Эрозии']),
            0,
        ],
        [
            get(dataTable, ['Запястно-пястные (ЗП) суставы', '3.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', '4.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', '5.0', 'Справа', 'Сужение щелей']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Сужение щелей']),
            0,
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Справа', 'Сужение щелей']),
        ],
        [
            0,
            0,
            0,
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Эрозии']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Слева', 'Эрозии']),
            0,
        ],
        [
            get(dataTable, ['Запястно-пястные (ЗП) суставы', '3.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', '4.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', '5.0', 'Слева', 'Сужение щелей']),
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Сужение щелей']),
            0,
            get(dataTable, ['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Слева', 'Сужение щелей']),
        ],
    ]);

    const debouncedChangeHandler = useMemo(
        () =>
            debounce(() => {
                setIsDebounced(false);
                saveTable({ fileName: resultProcess.jsonFileName, json: JSON.stringify(newForm) });
            }, 2000),
        []
    );

    const download = (blob: Blob, filename: string) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const onDownload = async ({ url, fileName }: { url: string; fileName: string }) => {
        const response = await fetch(url, {
            headers: {
                authorization: token || '',
            },
        });

        response.blob().then((blob) => download(blob, fileName));
    };

    const handleChange = (e: any) => {
        const inputs = e.target.form.querySelectorAll('input');

        inputs.forEach((input: HTMLInputElement) => {
            set(newForm, input.name.split('/'), input.value);
        });

        debouncedChangeHandler();
        setIsDebounced(true);
    };

    const table = useMemo(() => {
        return (
            <form onChange={handleChange}>
                <table ref={tableRef} className="iksweb">
                    <tbody>
                        <tr>
                            <td align="center" rowSpan={2}>
                                Сустав
                            </td>
                            <td align="center" colSpan={2}>
                                Справа
                            </td>
                            <td align="center" colSpan={2}>
                                Слева
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Эрозии</td>
                            <td align="center">Сужение щелей</td>
                            <td align="center">Эрозии</td>
                            <td align="center">Сужение щелей</td>
                        </tr>
                        <tr>
                            <td align="center">1-й МФ</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Без имени', '1-й МФ', 'Справа', 'Эрозии']}
                                value={distalInterphalangealJoints[0][0]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Без имени', '1-й МФ', 'Справа', 'Сужение щелей']}
                                value={distalInterphalangealJoints[1][0]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Без имени', '1-й МФ', 'Слева', 'Эрозии']}
                                value={distalInterphalangealJoints[2][0]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Без имени', '1-й МФ', 'Слева', 'Сужение щелей']}
                                value={distalInterphalangealJoints[3][0]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center" colSpan={5}>
                                Дистальные межфаланговые (ДМФ) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">2</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Эрозии']}
                                value={distalInterphalangealJoints[0][1]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Сужение щелей']}
                                value={distalInterphalangealJoints[1][1]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Эрозии']}
                                value={distalInterphalangealJoints[2][1]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Сужение щелей']}
                                value={distalInterphalangealJoints[3][1]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Эрозии']}
                                value={distalInterphalangealJoints[0][2]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Сужение щелей']}
                                value={distalInterphalangealJoints[1][2]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Эрозии']}
                                value={distalInterphalangealJoints[2][2]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Сужение щелей']}
                                value={distalInterphalangealJoints[3][2]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Эрозии']}
                                value={distalInterphalangealJoints[0][3]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Сужение щелей']}
                                value={distalInterphalangealJoints[1][3]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Эрозии']}
                                value={distalInterphalangealJoints[2][3]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Сужение щелей']}
                                value={distalInterphalangealJoints[3][3]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Эрозии']}
                                value={distalInterphalangealJoints[0][4]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Сужение щелей']}
                                value={distalInterphalangealJoints[1][4]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Эрозии']}
                                value={distalInterphalangealJoints[2][4]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Сужение щелей']}
                                value={distalInterphalangealJoints[3][4]}
                                sumUpdate={(value: any) =>
                                    setDistalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                key={uniqueId()}
                                value={sum(distalInterphalangealJoints[0])}
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(distalInterphalangealJoints[1])}
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Справа', 'Сужение щелей']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(distalInterphalangealJoints[2])}
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(distalInterphalangealJoints[3])}
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Слева', 'Сужение щелей']}
                            />
                        </tr>
                        <tr>
                            <td align="center" colSpan={5}>
                                Проксимальные межфаланговые (ПМФ) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">2</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Эрозии']}
                                value={proximalInterphalangealJoints[0][0]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[1][0]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Эрозии']}
                                value={proximalInterphalangealJoints[2][0]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[3][0]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Эрозии']}
                                value={proximalInterphalangealJoints[0][1]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[1][1]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Эрозии']}
                                value={proximalInterphalangealJoints[2][1]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[3][1]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Эрозии']}
                                value={proximalInterphalangealJoints[0][2]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[1][2]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Эрозии']}
                                value={proximalInterphalangealJoints[2][2]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[3][2]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Эрозии']}
                                value={proximalInterphalangealJoints[0][3]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[1][3]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Эрозии']}
                                value={proximalInterphalangealJoints[2][3]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Сужение щелей']}
                                value={proximalInterphalangealJoints[3][3]}
                                sumUpdate={(value: any) =>
                                    setProximalInterphalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                key={uniqueId()}
                                value={sum(proximalInterphalangealJoints[0])}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(proximalInterphalangealJoints[1])}
                                name={[
                                    'Проксимальные межфаланговые (ПМФ) суставы',
                                    'Суммарно',
                                    'Справа',
                                    'Сужение щелей',
                                ]}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(proximalInterphalangealJoints[2])}
                                name={['Проксимальные межфаланговые (ПМФ) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(proximalInterphalangealJoints[3])}
                                name={[
                                    'Проксимальные межфаланговые (ПМФ) суставы',
                                    'Суммарно',
                                    'Слева',
                                    'Сужение щелей',
                                ]}
                            />
                        </tr>
                        <tr>
                            <td align="center" colSpan={5}>
                                Пястно-фаланговые (ПЯФ) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">1</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Эрозии']}
                                value={metacarpophalangealJoints[0][0]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Сужение щелей']}
                                value={metacarpophalangealJoints[1][0]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Эрозии']}
                                value={metacarpophalangealJoints[2][0]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Сужение щелей']}
                                value={metacarpophalangealJoints[3][0]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">2</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Эрозии']}
                                value={metacarpophalangealJoints[0][1]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Сужение щелей']}
                                value={metacarpophalangealJoints[1][1]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Эрозии']}
                                value={metacarpophalangealJoints[2][1]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Сужение щелей']}
                                value={metacarpophalangealJoints[3][1]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Эрозии']}
                                value={metacarpophalangealJoints[0][2]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Сужение щелей']}
                                value={metacarpophalangealJoints[1][2]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Эрозии']}
                                value={metacarpophalangealJoints[2][2]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Сужение щелей']}
                                value={metacarpophalangealJoints[3][2]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Эрозии']}
                                value={metacarpophalangealJoints[0][3]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Сужение щелей']}
                                value={metacarpophalangealJoints[1][3]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Эрозии']}
                                value={metacarpophalangealJoints[2][3]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Сужение щелей']}
                                value={metacarpophalangealJoints[3][3]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Эрозии']}
                                value={metacarpophalangealJoints[0][4]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Сужение щелей']}
                                value={metacarpophalangealJoints[1][4]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Эрозии']}
                                value={metacarpophalangealJoints[2][4]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Сужение щелей']}
                                value={metacarpophalangealJoints[3][4]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">1-й ЗП сустав</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Справа', 'Эрозии']}
                                value={metacarpophalangealJoints[0][5]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][5] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Слева', 'Эрозии']}
                                value={metacarpophalangealJoints[2][5]}
                                sumUpdate={(value: any) =>
                                    setMetacarpophalangealJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][5] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <td className="td-x" align="center">
                                X
                            </td>
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                key={uniqueId()}
                                value={sum(metacarpophalangealJoints[0])}
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(metacarpophalangealJoints[1])}
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Справа', 'Сужение щелей']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(metacarpophalangealJoints[2])}
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(metacarpophalangealJoints[3])}
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Слева', 'Сужение щелей']}
                            />
                        </tr>
                        <tr>
                            <td align="center" colSpan={5}>
                                Запястно-пястные (ЗП) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <td className="td-x" align="center" rowSpan={3}>
                                X
                            </td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', '3.0', 'Справа', 'Сужение щелей']}
                                value={wristJoints[1][0]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <td className="td-x" align="center" rowSpan={3}>
                                X
                            </td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', '3.0', 'Слева', 'Сужение щелей']}
                                value={wristJoints[3][0]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][0] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', '4.0', 'Справа', 'Сужение щелей']}
                                value={wristJoints[1][1]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', '4.0', 'Слева', 'Сужение щелей']}
                                value={wristJoints[3][1]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][1] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', '5.0', 'Справа', 'Сужение щелей']}
                                value={wristJoints[1][2]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', '5.0', 'Слева', 'Сужение щелей']}
                                value={wristJoints[3][2]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][2] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">Запястье</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Эрозии']}
                                value={wristJoints[0][3]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Сужение щелей']}
                                value={wristJoints[1][3]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Эрозии']}
                                value={wristJoints[2][3]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Сужение щелей']}
                                value={wristJoints[3][3]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][3] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr>
                            <td align="center">Локтевая кость</td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Справа', 'Эрозии']}
                                value={wristJoints[0][4]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[0][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Слева', 'Эрозии']}
                                value={wristJoints[2][4]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[2][4] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <td className="td-x" align="center">
                                X
                            </td>
                        </tr>
                        <tr>
                            <td align="center">ЛЗ</td>
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Справа', 'Сужение щелей']}
                                value={wristJoints[1][5]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[1][5] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd
                                key={uniqueId()}
                                name={['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Слева', 'Сужение щелей']}
                                value={wristJoints[3][5]}
                                sumUpdate={(value: any) =>
                                    setWristJoints((prevState) => {
                                        const newState = cloneDeep(prevState);
                                        newState[3][5] = Number(value);
                                        return newState;
                                    })
                                }
                            />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                key={uniqueId()}
                                value={sum(wristJoints[0])}
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(wristJoints[1])}
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Справа', 'Сужение щелей']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(wristJoints[2])}
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum(wristJoints[3])}
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Слева', 'Сужение щелей']}
                            />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Общая сумма</td>
                            <SumTd
                                key={uniqueId()}
                                value={sum([
                                    sum(distalInterphalangealJoints[0]),
                                    sum(proximalInterphalangealJoints[0]),
                                    sum(metacarpophalangealJoints[0]),
                                    sum(wristJoints[0]),
                                ])}
                                name={['Общая сумма', 'Справа', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum([
                                    sum(distalInterphalangealJoints[1]),
                                    sum(proximalInterphalangealJoints[1]),
                                    sum(metacarpophalangealJoints[1]),
                                    sum(wristJoints[1]),
                                ])}
                                name={['Общая сумма', 'Справа', 'Сужение щелей']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum([
                                    sum(distalInterphalangealJoints[2]),
                                    sum(proximalInterphalangealJoints[2]),
                                    sum(metacarpophalangealJoints[2]),
                                    sum(wristJoints[2]),
                                ])}
                                name={['Общая сумма', 'Слева', 'Эрозии']}
                            />
                            <SumTd
                                key={uniqueId()}
                                value={sum([
                                    sum(distalInterphalangealJoints[3]),
                                    sum(proximalInterphalangealJoints[3]),
                                    sum(metacarpophalangealJoints[3]),
                                    sum(wristJoints[3]),
                                ])}
                                name={['Общая сумма', 'Слева', 'Сужение щелей']}
                            />
                        </tr>
                    </tbody>
                </table>
            </form>
        );
    }, [
        dataTable,
        resultProcess,
        distalInterphalangealJoints,
        metacarpophalangealJoints,
        proximalInterphalangealJoints,
        wristJoints,
    ]);

    return (
        <div>
            <div className="d-flex justify-between">
                <Button type="ghost" size="small" handler={onResetDataTable}>
                    <div className="d-flex align-center gap-5">
                        <IconRotateCcw /> К исходным значениям
                    </div>
                </Button>
                <div className="d-flex align-center gap-10">
                    <div className="mr-10">Скачать:</div>
                    <Button
                        popover="Excel"
                        type="ghost"
                        size="small"
                        handler={() => onDownload({ url: resultProcess.excelLink, fileName: 'table.xlsx' })}
                    >
                        <IconFile />
                    </Button>
                    <Button href={resultProcess.fileLink} popover="Фото" type="ghost" size="small" download={true}>
                        <IconImage />
                    </Button>
                    <Button
                        popover="Архив"
                        type="ghost"
                        size="small"
                        handler={() => onDownload({ url: resultProcess.archiveLink, fileName: 'archive.zip' })}
                    >
                        <IconArchive />
                    </Button>
                </div>
            </div>
            {table}
        </div>
    );
};

export { Table };
