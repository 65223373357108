import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Panzoom } from '@fancyapps/ui';
import cloneDeep from 'lodash/cloneDeep';
import '@fancyapps/ui/dist/panzoom.css';
import { RootState } from 'app/store';
import { Table } from './Table';
import './index.css';

const Result = () => {
    const { resultProcess } = useSelector((state: RootState) => state.xray);
    const [dataTable, setInitData] = useState(resultProcess && resultProcess.table);

    const handleResetDataToInit = () => {
        setInitData((prevState: unknown) => cloneDeep(prevState));
    };

    useEffect(() => {
        const elsPanzoom = document.querySelectorAll('.panzoom');

        if (elsPanzoom) {
            elsPanzoom.forEach((elPanzoom) => {
                const panzoom = new Panzoom(elPanzoom, {});
            });
        }
    }, []);

    return (
        <div className="container">
            {resultProcess && (
                <div className="F-Xray-Result__response-data">
                    <div className="F-Xray-Result__grid">
                        <Table
                            resultProcess={resultProcess}
                            dataTable={dataTable}
                            onResetDataTable={handleResetDataToInit}
                        />
                        <div className="F-Xray-Result__wrap-files">
                            <div className="F-Xray-Result__wrap-files-grid">
                                <div
                                    className="F-Xray-Result__response-data-text"
                                    dangerouslySetInnerHTML={{ __html: resultProcess && resultProcess.diagnosis }}
                                />
                                <br />
                                {resultProcess && (
                                    <div id="panzoom" className="panzoom">
                                        <img
                                            src={resultProcess.fileLink}
                                            className="F-Xray__responsive-img panzoom__content"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export { Result };
