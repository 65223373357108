import { ChangeEventHandler, ClipboardEventHandler, useRef } from 'react';
import { useUploadFileMutation } from 'shared/api';
import { Button } from 'shared/ui/Button';
import './index.css';

type UploaderProps = {
    selectedFile: File | null;
    selectFile: (file: File | null) => void;
};

const Uploader = ({ selectedFile, selectFile }: UploaderProps) => {
    const [uploadFile, { isLoading }] = useUploadFileMutation();
    const inputEl = useRef<HTMLInputElement | null>(null);

    const handleChangeInput: ChangeEventHandler = (event) => {
        const target = event.target as HTMLInputElement;
        const files = target.files;
        if (files) {
            selectFile(files[0]);
        }
    };

    const handleClickUploadButton = () => {
        if (inputEl && inputEl.current) {
            inputEl.current.click();
        }
    };

    const handlePaste: ClipboardEventHandler = (event) => {
        if (event.clipboardData && event.clipboardData.files.length) {
            selectFile(event.clipboardData.files[0]);
        } else {
            alert('В вашем буфере нет файлов. Скопируйте файл!');
        }
    };

    return (
        <div className="F-Xray-Uploader" onPaste={handlePaste}>
            {selectedFile ? (
                <>
                    <img
                        src={URL.createObjectURL(selectedFile)}
                        alt={selectedFile.name}
                        className="F-Xray-Uploader__preview"
                    />
                    {isLoading ? (
                        <div className="F-Xray-Uploader__spin" />
                    ) : (
                        <>
                            <Button type="primary" handler={() => uploadFile({ file: selectedFile })}>
                                Обработать снимок
                            </Button>
                            <br />
                            <br />
                            <Button type="link" handler={() => selectFile(null)}>
                                Отменить
                            </Button>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div>
                        <input type="file" hidden ref={inputEl} onChange={handleChangeInput} />
                        <Button type="ghost" handler={handleClickUploadButton}>
                            Загрузить снимок
                        </Button>
                    </div>
                    <div className="F-Xray-Uploader__or">Или</div>
                    <div className="F-Xray-Uploader__caption"></div>
                    <div className="F-Xray-Uploader__keys">
                        Вставить снимок <span className="F-Xray__tag">ctrl</span> +{' '}
                        <span className="F-Xray__tag">V</span>
                    </div>
                </>
            )}
        </div>
    );
};

export { Uploader };
