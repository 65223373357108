import { createSlice } from '@reduxjs/toolkit';
import { logOut } from 'features/auth/slice';
import { XrayState } from './data';
import { appApi } from 'shared/api';

const initialState: XrayState = {
    resultProcess: null,
};

const xraySlice = createSlice({
    name: 'xray',
    initialState,
    reducers: {
        resetProcessResult: (state) => {
            state.resultProcess = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logOut, (state) => {
            state.resultProcess = null;
        });
        builder.addMatcher(appApi.endpoints.uploadFile.matchFulfilled, (state, { payload }) => {
            state.resultProcess = payload;
        });
    },
});

export const { resetProcessResult } = xraySlice.actions;
export default xraySlice.reducer;
